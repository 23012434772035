import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { roundOfToInt, roundOfTwoDigits } from 'utils/formattingUtils';
import Information from '../../assets/img/Information.svg';
// import CartItems from './CartItems';

export default function FooterCart({
  events,
  amount,
  onClick,
  onPrevious,
  cartSummary,
  text,
  hideBackButton,
}) {
  const [showCredit] = useState(false); // implement the info functionality
  const [showPaymentCart, setShowPaymentCart] = useState(false); // implement the info functionality

  const params = useParams();

  return (
    <>
      <div className="hidden md:flex fixed py-4 md:py-0 bottom-0 w-full md:h-20 bg-white font-roboto shadow-footer gap-3 md:gap-0 flex-col md:flex-row px-4 md:px-10 lg:px-20 justify-between items-center">
        <div>
          {!hideBackButton && (
            <button
              className="border reg-btn-outline-dark text-sm hover:bg-gray-250 font-semibold bg-white px-2 lg:px-4 py-2 rounded-lg transition-colors"
              onClick={onPrevious || (() => {})}
            >
              Go to Previous Step
            </button>
          )}
        </div>
        <div className="cart-info text-center flex">
          {params.user !== 'coach' ? (
            <div className="shadow-shadowRight flex items-center">
              {/* <span className="p-2.5 text-gray-500">
                SFA Credit : ₹ {cartSummary?.data?.available_credit || 0}
              </span> */}
              {/* <img
                className="w-7 h-7 mr-4 inline-block"
                alt="info icon"
                src={Information}
                onMouseEnter={() => {
                  setShowCredit(true);
                }}
                onMouseLeave={() => {
                  setShowCredit(false);
                }}
              /> */}
              {showCredit ? (
                <h1 className="absolute py-2 px-4 bg-gray-750 text-white -mt-16 ml-40 shadow-popUpBackground w-48 text-xs text-left rounded">
                  SFA Credit is earned through participating, referring and signing up to the
                  platform.
                  <br />1 SFA Credit = 1 Rupee
                </h1>
              ) : null}
            </div>
          ) : null}

          <span
            className={`py-2.5 px-2.5 text-gray-500 ${
              params.user !== 'coach' ? 'shadow-shadowRight' : 'mr-4'
            }`}
          >
            Event Added : {events}
          </span>
          {params.user !== 'coach' ? (
            <div className="flex items-center">
              <span className="p-2.5 text-gray-500">Total Amount : ₹ {roundOfToInt(amount)}</span>
              {cartSummary?.data?.sport_events?.length > 0 ? (
                <img
                  className="w-7 h-7 mr-4 inline-block"
                  alt="info icon"
                  src={Information}
                  onMouseEnter={() => setShowPaymentCart(true)}
                  onMouseLeave={() => setShowPaymentCart(false)}
                />
              ) : null}
              {showPaymentCart ? (
                <div className="absolute border-1 border-gray-150 -mt-52 ml-48 z-20 w-64 text-sm shadow-cartPaymentPopUp bg-white rounded px-4 py-6">
                  <p className="font-medium text-left mb-4 text-sm">Total Amount</p>
                  <div className="shadow-shadowBottom text-xs pb-2">
                    {cartSummary?.data
                      ? cartSummary?.data?.sport_events?.map((item, index) => {
                          return (
                            <div key={index} className="flex justify-between gap-4">
                              <span className="text-gray-500">
                                {item.sport_event.split('-')[1]}
                              </span>
                              <span className="text-gray-500 font-semibold">
                                ₹ {roundOfTwoDigits(item.price)}
                              </span>
                            </div>
                          );
                        })
                      : null}
                    {cartSummary?.data?.tournament_fees > 0 ? (
                      <div className="flex justify-between">
                        <span className="text-gray-500">One-Time Tournament Fees</span>
                        <span className="text-gray-500 font-semibold">
                          ₹ {roundOfTwoDigits(cartSummary?.data?.tournament_fees)}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="py-2 shadow-shadowBottom text-xs">
                    {cartSummary.data && cartSummary.data.sport_events.length > 0
                      ? cartSummary.data.taxes.map((item, index) => {
                          return (
                            <div key={index} className="flex justify-between gap-4">
                              <span className="text-gray-500">{item.tax_name}</span>
                              <span className="text-gray-500 font-semibold">
                                ₹ {roundOfTwoDigits(item.amount)}
                              </span>
                            </div>
                          );
                        })
                      : null}
                  </div>
                  <div className="flex justify-between gap-4 py-2 text-xs">
                    <p>To Be Paid*</p>
                    <p className="font-semibold">₹ {roundOfToInt(amount)}</p>
                    {/* <p className="font-semibold">₹ {cartSummary?.data?.to_be_paid}</p> */}
                  </div>

                  <p className="pt-2 text-gray-450 text-xxs text-left">
                    This amount does not include SFA Credit and coupon discount. You've to apply
                    them separately during payment
                  </p>
                </div>
              ) : null}
            </div>
          ) : null}
          <button
            disabled={!cartSummary?.data?.sport_events?.length > 0}
            className={`text-sm font-semibold text-center text-white py-2 px-6 rounded  border  hover:border-transparent transition-colors duration-200  ${
              !cartSummary?.data?.sport_events?.length > 0
                ? 'bg-black opacity-50 border-black'
                : 'bg-gray-750 hover:bg-blue-800 border-gray-750'
            }`}
            // className="px-6  py-2 rounded-lg text-white bg-gray-750 transition-colors duration-200 hover:text-gray-750 hover:bg-white hover:border-gray-750 border-1"
            onClick={onClick || (() => {})}
          >
            {text || (
              <p>
                {' '}
                Continue <span className="hidden md:inline-block">to Payment</span>
              </p>
            )}
          </button>
        </div>
      </div>

      {/* Small Screens */}
      <div className="md:hidden fixed py-4 bottom-0 w-full md:h-20 bg-white font-roboto shadow-footer flex gap-3 md:gap-0 flex-col md:flex-row px-4 md:px-20 justify-between items-center">
        <div className="cart-info text-gray-750 text-center flex w-full">
          {/* <div className="shadow-shadowRight flex-grow">
            <p className="text-xxs">SFA Credit</p>
            <h3 className="font-bold text-xs mt-1">₹ 200.00</h3>
          </div> */}
          <div className="shadow-shadowRight flex-grow">
            <p className="text-xxs">Event Added</p>
            <h3 className="font-bold text-xs mt-1">{events}</h3>
          </div>
          {params.user !== 'coach' ? (
            <div className="flex-grow">
              <div className="flex gap-1.5 justify-center items-center ">
                <p className="text-xxs">Total Amount</p>
                {/* <img className="w-3 h-3 inline-block" alt="info icon" src={Information} /> */}
              </div>
              <h3 className="font-bold text-xs mt-1">₹ {roundOfToInt(amount)}</h3>
            </div>
          ) : null}
        </div>
        <div className="flex gap-3 w-full items-center">
          {!hideBackButton && (
            <button
              className="border reg-btn-outline-dark text-sm hover:bg-gray-250 font-semibold bg-white px-2 lg:px-4 py-2 rounded-lg transition-colors"
              // className="text-sm font-semibold border border-gray-750 bg-white px-2 lg:px-4 py-2 rounded-lg transition-colors duration-300 hover:text-white hover:bg-gray-750"
              onClick={onPrevious || (() => {})}
            >
              Go to Previous Step
            </button>
          )}
          <button
            disabled={!cartSummary?.data?.sport_events?.length > 0}
            className={`px-6 flex-grow md:mt-0 py-2 rounded-lg text-white transition-colors duration-200 text-sm font-semibold hover:border-transparent border-1 ${
              !cartSummary?.data?.sport_events?.length > 0
                ? 'bg-black opacity-50 border-black'
                : 'bg-gray-750 hover:bg-blue-800 border-gray-750'
            }`}
            onClick={onClick || (() => {})}
          >
            Continue <span className="hidden md:inline-block">to Payment</span>
          </button>
        </div>
      </div>
    </>
  );
}

export function Footer1Button(props) {
  return (
    <>
      <div className="fixed bottom-0 w-full h-20 bg-white font-roboto shadow-footer flex px-0 justify-center md:px-44 md:justify-end">
        <button
          onClick={props?.onClick}
          className="my-5 text-sm font-semibold text-center text-white py-2 px-6 rounded bg-gray-750 border border-gray-750 hover:border-transparent transition-colors duration-200 hover:bg-blue-800"
          // className="px-6 my-5 py-2 rounded-lg text-white bg-gray-750 transition-colors duration-200 hover:text-gray-750 hover:bg-white hover:border-gray-750 border-1"
        >
          {props?.nextButtonText ? props?.nextButtonText : 'Continue'}
        </button>
      </div>
    </>
  );
}

export function Footer2Button(props) {
  const params = useParams();
  const showBack = params.user === 'athlete' && props.showBack;
  const hideNext = props.hideNext;
  return (
    <>
      <div
        className={`fixed bottom-0 py-3 md:py-0 w-full h-auto md:h-20 bg-white font-roboto shadow-footer flex flex-wrap gap-3 md:gap-0 px-4 md:px-40 justify-center md:justify-${
          showBack || hideNext ? 'between' : 'end'
        } items-center`}
      >
        {showBack && (
          <button
            className="border reg-btn-outline-dark text-sm hover:bg-gray-250 font-semibold bg-white px-2 lg:px-4 py-2 rounded-lg transition-colors duration-300"
            // className="text-sm font-semibold border border-gray-450 bg-white px-6 py-2 rounded-lg transition-colors duration-300 hover:text-white hover:bg-gray-750"
            onClick={props?.onPrevious}
          >
            {!props.inviteId ? (
              <>
                <span className="hidden md:inline-block">{'Go to Previous Step'}</span>
                <span className="md:hidden">{'Go Back'}</span>
              </>
            ) : (
              <>
                <span className="hidden md:inline-block">{'Add More Events'}</span>
                <span className="md:hidden">{'Add More Events'}</span>
              </>
            )}
          </button>
        )}
        {hideNext ? null : (
          <button
            className="text-sm font-semibold text-center text-white py-2 px-6 rounded-lg bg-gray-750 border border-gray-750 hover:border-transparent transition-colors duration-200 hover:bg-blue-800"
            // className="px-6  py-2 rounded-lg text-white bg-gray-750 transition-colors duration-200 hover:text-gray-750 hover:bg-white hover:border-gray-750 border-1"
            onClick={props?.onClick}
            disabled={props.isLoading}
          >
            {props?.nextButtonText
              ? props?.nextButtonText
              : props?.isLoading
              ? 'Loading...'
              : 'Continue'}
          </button>
        )}
      </div>
    </>
  );
}

export function Footer2ButtonAuth(props) {
  return (
    <>
      <div className="fixed md:hidden bottom-0 py-3 md:py-0 w-full h-auto md:h-20 bg-white font-roboto shadow-footer flex flex-wrap gap-3 md:gap-0 px-4 md:px-40 justify-center md:justify-between items-center">
        <button
          className="px-6 flex-grow md:flex-grow-0 py-2 md:my-5 whitespace-nowrap border-2 border-gray-750 text-gray-750 hover:bg-gray-250 text-base font-bold rounded-lg"
          onClick={props?.onCreate}
        >
          {/* <span className="hidden md:inline-block"></span> */}
          <span className="md:hidden">Create Account</span>
        </button>
        <button
          className="bg-gray-750 hover:bg-blue-800 flex-grow md:flex-grow-0 px-6 py-2.5 md:my-5 text-white text-base font-bold rounded-lg"
          data-cy="submit"
          type="submit"
          form="signIn-form"
          disabled={props?.isSubmitting}
        >
          Sign In
        </button>
      </div>
    </>
  );
}

export function Footer3Button(props) {
  return (
    <>
      <div className="fixed bottom-0 py-3 md:py-0 w-full h-auto md:h-20 bg-white font-roboto shadow-footer flex flex-wrap gap-3 md:gap-0 px-4 md:px-40 justify-center md:justify-between items-center">
        <button
          className="border reg-btn-outline-dark text-sm hover:bg-gray-250 font-semibold bg-white px-2 lg:px-4 py-2 rounded-lg transition-colors duration-300"
          // className="text-sm font-semibold border border-gray-450 bg-white px-6 py-2 rounded-lg transition-colors duration-300 hover:text-white hover:bg-gray-750"
          onClick={props?.onPrevious}
        >
          <span className="hidden md:inline-block">Go to Previous Step</span>
          <span className="inline-block md:hidden">Back</span>
        </button>
        <div className="flex gap-4">
          {/* <button
            className="border border-gray-450 bg-white px-6 py-2 rounded-lg transition-colors duration-300 hover:text-white hover:bg-gray-750"
            onClick={props?.onCancelPayment}
          >
            Cancel
          </button> */}
          {props?.size > 0 ? (
            <button
              className="text-sm font-semibold text-center text-white py-2 px-6 rounded-lg bg-gray-750 border border-gray-750 hover:border-transparent transition-colors duration-200 hover:bg-blue-800"
              // className="px-6  py-2 rounded-lg text-white bg-gray-750 transition-colors duration-200 hover:text-gray-750 hover:bg-white hover:border-gray-750 border-1"
              onClick={props?.onClick}
              disabled={props.isLoading}
            >
              {props?.nextButtonText
                ? props?.nextButtonText
                : props?.isLoading
                ? 'Loading...'
                : 'Continue'}
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
}
